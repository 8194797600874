import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const RightMenu = () =>{

    const isMobile = window.matchMedia("(max-width:900px)").matches;

    const navigate = useNavigate();

    const scrollHandler = () =>{
        navigate("/");
        if(isMobile){
            window.scroll({top:0, left:0, behavior:"smooth"});
        }
        
    }

    return (
        <div className="right-menu">
            <div className="right-menu__menu-wrapper" onClick={scrollHandler}>
                {/* <Link to="/"><i class="fa-solid fa-home"></i></Link> */}
                <i className="fa-solid fa-home home-icon"></i>
            </div>
            <p className="right-menu__contact"><a href="mailto:r.barsanti85@gmail.com">Kontakt mich!</a></p>
        </div>
    )
}

export default RightMenu;