import React from "react";
import Button from "./Button";

const Box = (props) =>{
    const {
        title, 
        content, 
        link, 
        sectionId,
        isAccessible,
    } = props;

    const lorem = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.";
    return (
        <div className="box">
            <h3>{title}</h3>
            <p dangerouslySetInnerHTML={{__html: content || lorem}}/>
            <div>
            <Button 
            title="Mehr entdecken"
            disabled={isAccessible === -1}
            mode="contained"
            color="main"
            route={`/section/${sectionId}`}
            buttonStyleConfig={{width:"100%", justifyContent:"center"}}
            />
            </div>
        </div>
    )
}

export default Box;