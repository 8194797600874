import React, {useState, useEffect} from "react";
import CircularProgress from "./CircularProgress";
import styles from '../styles/LoadingScreen.module.scss';
import useAppStore from "../store/store";

const LoadingScreen = () =>{

const [percentage, setPercentage] = useState(0);
const [isLoaded, setIsLoaded] = useState(false);
const [hasError, setHasError] = useState(false);

const setIsLoading = useAppStore(store=>store.setIsLoading);
const setHomePageIsLoaded = useAppStore(store=>store.setHomePageIsLoaded);
const errors = useAppStore(store=>store.errors);

const isPhone = window.matchMedia("(max-width:600px)").matches;
const isMobile = window.matchMedia("(max-width:900px)").matches;


useEffect(()=>{

    const interval = setInterval(()=>{

        if(percentage >= 100){
            clearInterval(interval);
            setTimeout(()=>{
                if(errors.categories){
                    setHasError(true)
                }else{
                    setIsLoaded(true);
                }
                setTimeout(()=>{
                    setIsLoading(false);
                    if(!errors.categories){
                        setHomePageIsLoaded(true);
                    } 
                },200)
            },400)
            
        }else{
            setPercentage(percentage=>percentage + 1);
        }
    },10);

    return ()=>clearInterval(interval);


},[percentage])

    return (
        <div className={styles.loadingScreen}>
            <div className={styles.loadingWrapper}>
                <CircularProgress
                percentage={percentage} 
                title={hasError ? "Whoops! Something went wrong. Please try again later." : isLoaded ? "Ready!" : "Loading Informations..."}
                speed={1}
                titleOptions={
                    {
                        position:"bottom",
                        style: {
                            fontSize: !isPhone ? "2.5rem" : "1.8rem",
                            textAlign: "center",
                            marginTop:"1.5rem",
                            background: "linear-gradient(90deg, var(--main), white)",
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                            whiteSpace: !isMobile && "nowrap",
                        }
                    }
                }
                />
            </div>
        </div>
    )
}

export default LoadingScreen;