import logo from './logo.svg';
import './App.scss';
import './Typography.scss';
import Main from './comps/Main';
import useAppStore from './store/store';



function App() {
  const homepageIsLoaded = useAppStore(store=>store.homepageIsLoaded);
  return (
    <div className="App" style={{height: !homepageIsLoaded && "100%"}}>
      <Main/>
    </div>
  );
}

export default App;
