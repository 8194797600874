import React from "react"

const InfoText = (props) =>{

    const {info} = props;

    return (
    <div className="info-text">
      <i className="fa-solid fa-check"></i>
      <p>{info}</p>
    </div>  
    )
}

export default InfoText; 