import React, { useRef, useState, useEffect } from "react";
import Header from "./Header";
import Button from "./Button";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import useAppStore from "../store/store";
import Alert from "./Alert";

const PageContentProject = () => {
  const { id: projectId } = useParams();

  const [currentProject, setCurrentProject] = useState({});

  const projects = useAppStore((store) => store.projects);

  const assetsEndpoint = process.env.REACT_APP_ASSETS_ENDPOINT;

  const navigate = useNavigate();

  const headerRef = useRef(null);

  const isMobile = window.matchMedia("(max-width:900px)").matches;

  const lorem =
    "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eligendi eum delectus mollitia placeat officiis aspernatur maiores perspiciatis, totam fugiat deserunt? Beatae fuga at cumque nobis quisquam harum iste vitae commodi.";

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        headerRef?.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
    const project = projects.find((e) => e.id == projectId);
    if (!project) {
      navigate("/");
    }
    console.log(project);
    setCurrentProject(project);
  }, []);

  return (
    <div ref={headerRef} className="page-project">
      <Header
        baseUrl={assetsEndpoint}
        image={currentProject?.featured_img || "project-header.jpg"}
        title={currentProject?.title}
      />
      <p
        className="description-text"
        dangerouslySetInnerHTML={{
          __html: currentProject?.long_description || lorem,
        }}
      />
      {currentProject?.has_alert === 1 && (
        <Alert>{currentProject?.alert_message}</Alert>
      )}
      <div className="page-project__buttons">
        <Button
          title="Zurück"
          color="main"
          action={() => navigate(-1)}
          mode="contained"
          icon="chevron-left"
        />
        <Button
          title="Zur Projekt"
          color="main"
          mode="contained"
          link={currentProject?.link}
          icon="eye"
        />
        {currentProject?.github_link && (
          <Button
            title="Auf Github ansehen"
            color="main"
            mode="contained"
            link={currentProject?.github_link}
            icon="github"
            iconLib="brands"
          />
        )}
      </div>
    </div>
  );
};

export default PageContentProject;
