import React, { useEffect } from "react";
import LeftMenu from "./LeftMenu";
import PageContent from "./PageContent";
import RightMenu from "./RightMenu";
import Header from "./Header";
import useAppStore from "../store/store";
import LoadingScreen from "./LoadingScreen";

const Main = () => {
  const isMobile = window.matchMedia("(max-width:900px)").matches;

  const categories = useAppStore((store) => store.categories);

  const homepageIsLoaded = useAppStore((store) => store.homepageIsLoaded);

  const fetchCategories = useAppStore((store) => store.fetchCategories);
  const fetchPersonalInfo = useAppStore((store) => store.fetchPersonalInfo);

  useEffect(() => {
    setTimeout(() => {
      fetchPersonalInfo();
      fetchCategories();
    }, 100);
  }, []);

  if (!homepageIsLoaded || !categories.length > 0) {
    return <LoadingScreen />;
  }

  return (
    <div className="main">
      {isMobile && (
        <Header image="header.jpg" title="Welcome to my Website!" type="home" />
      )}
      <LeftMenu />
      <PageContent />
      <RightMenu />
    </div>
  );
};

export default Main;
