import React from "react";
import Button from "./Button";
import AutoType from "./AutoType";
import useAppStore from "../store/store";

const Header = (props) => {
  const { image, title, type, baseUrl } = props;

  const personalInfo = useAppStore((store) => store.personalInfo);

  const styles = {
    backgroundImage: baseUrl
      ? `url(${baseUrl}/img/${image})`
      : `url(${process.env.PUBLIC_URL}/img/${image})`,
  };

  return (
    <div className="page-content__header" style={styles}>
      <div>
        <h1>{title}</h1>
        {type === "home" && <AutoType />}
        {type === "home" && (
          <Button
            color="main"
            title="Lebenslauf Herunterladen"
            download
            link={personalInfo.curriculum_url}
            mode="contained"
          />
        )}
      </div>
    </div>
  );
};

export default Header;
