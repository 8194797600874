import React, { useState } from "react";
import { Link } from "react-router-dom";

const Button = (props) => {
  const {
    color,
    title,
    action,
    link,
    download,
    route,
    icon,
    iconLib,
    mode,
    style,
    disabled,
    buttonStyleConfig,
  } = props;

  const [isHover, setIsHover] = useState(false);

  const buttonStyle = {
    backgroundColor:
      mode === "contained"
        ? isHover
          ? `var(--light-${color})`
          : `var(--${color})`
        : "",
    color: mode !== "contained" ? `var(--${color})` : "",
    padding: mode !== "contained" ? "0" : "",
    fontWeight: mode !== "contained" ? 400 : "",
    boxShadow: mode === "contained" ? "var(--shadow)" : "",
    ...buttonStyleConfig,
  };

  return (
    <div
      className="button-wrapper"
      style={{
        ...style,
        opacity: disabled && "0.5",
        cursor: disabled && "not-allowed",
      }}
    >
      {route ? (
        <Link
          className="button"
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          to={route}
          //style={{backgroundColor:`var(--${color})`}}
          style={{ ...buttonStyle, pointerEvents: disabled && "none" }}
        >
          {icon && <i className={`fa-solid fa-${icon}`}></i>} {title}
        </Link>
      ) : (
        <a
          className="button"
          onClick={action}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
          download={download}
          //style={{backgroundColor:`var(--${color})`}}
          style={buttonStyle}
          target={!download && "_blank"}
          href={link}
        >
          {icon && (
            <i className={`fa-${iconLib ? iconLib : "solid"} fa-${icon}`}></i>
          )}{" "}
          {title}
        </a>
      )}
    </div>
  );
};

export default Button;
