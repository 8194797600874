import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import PageContentSection from "./comps/PageContentSection";
import PageContentHome from "./comps/PageContentHome";
import PageContentProject from "./comps/PageContentProject";

//IMPORTANT: ALWAYS SET BASENAME WHEN THE PAGE IS LOCATED IN A SUBFOLDER OF A LIVE WEBSITE!!!!

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App />,
      children: [
        {
          path: "/",
          element: <PageContentHome />,
        },
        {
          path: "/section/:id",
          element: <PageContentSection />,
        },
        {
          path: "/project/:id",
          element: <PageContentProject />,
        },
      ],
    },
  ],
  {
    basename: "/about",
  }
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
