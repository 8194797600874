import React from "react";
import Header from "./Header";
import Box from "./Box";
import useAppStore from "../store/store";

const PageContentHome = () =>{

    const isMobile = window.matchMedia("(max-width:900px)").matches;

    const categories = useAppStore(store=>store.categories);

    return (
        <>
        { !isMobile &&
        <Header 
        image="header.jpg"
        title="Welcome to my Website!"
        type="home"
        />
        }
            <h2>Meine Projekte</h2>
            <div className="box-grid">
                {
                    categories.map(category=>(
                        <Box
                        key={category.title}
                        title={category.title}
                        content={category.description}
                        sectionId={category.id}
                        isAccessible={category.is_accessible}
                        />
                    ))
                }
            </div>
            </>
    )
}

export default PageContentHome;