import React, {useState, useEffect} from "react"

const Bar = (props) =>{
    const {title, color, progress} = props

    const [barProgress, setBarProgress] = useState(0);

    useEffect(()=>{
        setBarProgress(progress);
    },[])

    return (
    <div className="bar-wrapper">
        <p>{title}</p>
        <div className="bar">
            <div 
            className="bar-progress" 
            style={{width: `${barProgress}%`, backgroundColor: `var(--${color})`}}
            />
        </div>
    </div>  
    )
}

export default Bar; 