import React from "react";
import Header from "./Header";
import Box from "./Box";
import PageContentHome from "./PageContentHome";
import {
    Outlet
  } from "react-router-dom";
import PageContentSection from "./PageContentSection";
import Button from "./Button";
import Chip from "./Chip";

const ProjectBox = (props) =>{

    const {
        id, 
        title, 
        description, 
        tools,
        link,
    } = props;

    const lorem = "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua";


    return (
        <div className="project-box">

            <div className="project-box__content">
                <h3>{title}</h3>
                <p dangerouslySetInnerHTML={{__html: description || lorem}}/>
                <div className="project-box__tools-list">
                    {tools?.map(tool=>(
                        <Chip
                            key={tool.id}
                            name={tool.name}
                            icon={tool.icon}
                            source={tool.source}
                        />
                    ))}
                </div>
            </div>
            <div className="project-box__buttons">
                <Button 
                title="Mehr Info" 
                color="main" 
                icon="circle-info"
                route={`../project/${id}`}
                />
                <Button 
                title="Ansehen" 
                color="main" 
                icon="eye"
                link={link}
                />
            </div>
        </div>
    )
}

export default ProjectBox;