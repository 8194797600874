import React from "react";
import CIcon from '@coreui/icons-react';
import * as uIcon from '@coreui/icons';

const Chip = (props) =>{
    const {icon, name, source} = props;

    return (
        <div className="chip">
            { source === "font-awesome" ?
            <i className={`fa-brands fa-${icon}`}></i> :
            <CIcon 
            icon={uIcon[icon]}
            style={{'fill': 'white', 'width': '14px', 'marginRight': "5px"}}
            />
            }
            <p>{name}</p>
        </div>
    )
}

export default Chip;