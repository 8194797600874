import React from "react";
import { TypeAnimation } from 'react-type-animation';

const AutoType = (props) =>{
    return (
        <div className="auto-type">
        <TypeAnimation
      sequence={[
        // Same substring at the start will only be typed out once, initially
        'I build Web-Applications',
        1000, // wait 1s before replacing "Mice" with "Hamsters"
        'I build design Mockups',
        1000,
        'I build Websites',
        1000,
        'I build Automation Tools',
        1000
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '16px', display: 'inline-block' }}
      repeat={Infinity}
    />
    </div>
    )
}

export default AutoType;