import React from "react";

const ContentInfo = (props) =>{
    const {title, info} = props;
    return (
        <div className="content-info">
            <p><b>{title}:</b></p>
            <p className="color-gray">{info}</p>
        </div>
    )
}

export default ContentInfo;