import React from "react";
import ContentInfo from "./ContentInfo";
import Divider from "./Divider";
import CircularProgress from "./CircularProgress";
import Bar from "./Bar";
import InfoText from "./InfoText";
import useAppStore from "../store/store";

const LeftMenu = () => {
  const personalInfo = useAppStore((store) => store.personalInfo);

  return (
    <div className="left-menu">
      <div className="left-menu__header">
        <div className="left-menu__portrait">
          <img src={personalInfo.personal_image_url} alt="" />
        </div>
        <h3>Riccardo Barsanti</h3>
        <p className="color-gray">Front End / Back End Entwickler</p>
      </div>
      <div className="left-menu__content">
        <ContentInfo title="Geburstdatum" info="24.08.1985" />
        <ContentInfo title="Geburstort" info="Pisa, Italien" />
        <ContentInfo title="Wohnort" info={personalInfo.location} />
        <Divider />
        <div className="circular-wrapper">
          <CircularProgress percentage={100} title="Italienisch" />
          <CircularProgress percentage={80} title="Deutsch" />
          <CircularProgress percentage={80} title="Englisch" />
        </div>
        <Divider />
        <Bar title="HTML" color="main" progress={100} />
        <Bar title="CSS" color="main" progress={100} />
        <Bar title="Javascript" color="main" progress={90} />
        <Bar title="SQL" color="main" progress={90} />
        <Bar title="Wordpress" color="main" progress={100} />
        <Bar title="PHP" color="main" progress={70} />
        <Bar title="Python" color="main" progress={60} />
        <Divider />
        <InfoText info="Moderne Frameworks: React, Angular, Next Js" />
        <InfoText info="Bootstrap, React MUI" />
        <InfoText info="Node JS und Express" />
        <InfoText info="GIT Kenntnisse" />
        <InfoText info="No SQL Datenbanken wie MongoDB" />
        <InfoText info="Wordpress DIVI Builder" />
        <InfoText info="...Viel mehr!" />
      </div>
      <div className="left-menu__footer">
        <i className="fa-brands fa-linkedin"></i>
        <i className="fa-brands fa-instagram"></i>
        <i className="fa-brands fa-facebook"></i>
      </div>
    </div>
  );
};

export default LeftMenu;
