import React, {useRef, useState, useEffect} from "react";
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import Header from "./Header";
import ProjectBox from "./ProjectBox";
import useAppStore from "../store/store";
import CircularProgress from "./CircularProgress";

const PageContentSection = () =>{

    const [sectionTitle, setSectionTitle] = useState("");
    const [sectionDescription, setSectionDescription] = useState("");

    const headerRef = useRef(null);
    const navigate = useNavigate();

    const isMobile = window.matchMedia("(max-width:900px)").matches;

    const fetchProjects = useAppStore(store=>store.fetchProjects);
    const categories = useAppStore(store=>store.categories);
    const projects = useAppStore(store=>store.projects);
    const projectsAreLoaded = useAppStore(store=>store.projectsAreLoaded);

    const {id} = useParams();

    useEffect(()=>{
        if(isMobile){
            setTimeout(()=>{
                headerRef?.current?.scrollIntoView({behavior:"smooth"});
            },100)
            
        }


        const category = categories.find(cat=>cat.id == id);

        if(!category || category.is_accessible === -1){
            navigate("/");
        }

        setSectionTitle(category?.title);
        setSectionDescription(category?.description);
        fetchProjects(id)

    },[]);

    return (
        <div ref={headerRef}>
            <Header 
            image="header-section.jpg"
            title={sectionTitle}
            />
            <br />
            <p dangerouslySetInnerHTML={{__html: sectionDescription}} className="description-text"/>
            {
                !projectsAreLoaded ? 

                <div style={{display:"flex", justifyContent:"center", paddingTop:"5rem"}}>
                <CircularProgress 
                percentage={100}
                wrapperStyle={{width:100}}
                speed={10}
                />
                </div>
                 :
                <div className="box-grid">
                {
                    projects.map(project=>(

                        <ProjectBox
                            key={project.id}
                            id={project.id}
                            title={project.title}
                            tools={project.tools}
                            link={project.link}
                            description={project.long_description}
                        />
                    ))
                }
            </div>
            }
            
        </div>
    )
}

export default PageContentSection;