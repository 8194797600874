import React from "react";
import Header from "./Header";
import Box from "./Box";
import PageContentHome from "./PageContentHome";
import {
    Outlet
  } from "react-router-dom";
import PageContentSection from "./PageContentSection";

const PageContent = () =>{


    return (
        <div className="page-content">
            <Outlet/>
        </div>
    )
}

export default PageContent;