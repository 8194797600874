import React, {useState, useEffect} from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = (props) =>{
    const {percentage, title, speed, wrapperStyle, titleOptions} = props;

    const [progress, setProgress] = useState(0);

    useEffect(()=>{
        setTimeout(()=>{
            setProgress(percentage);
        }, speed || 500)
        
    },[percentage])

    const Title = () =>(
        <p style={titleOptions?.style}>{title}</p>
    )


    return (
        <div className="circular-box" style={wrapperStyle} >
        { (titleOptions?.position === "top" || !titleOptions?.position) && <Title/> } 
       <CircularProgressbar 
       minValue={0} 
       value={progress} 
       text={`${progress}%`}
       styles={buildStyles({
        textSize:"16px",
        pathColor:"#448deb",
        textColor:"#448deb",
        //backgroundColor:"#8c8c8e",
       })}
       />
       {titleOptions?.position === "bottom" && <Title/> } 
       </div>
    )
}

export default CircularProgress;