import React from 'react';
import styles from '../styles/Components.module.scss'

const Alert = (props) =>{
    const {children, type} = props;

    // const colors = {
    //     default: "var(--light-main)",
    //     error: 
    // }


    return (
        <div className={styles.Alert}>
            <i class="fa-solid fa-circle-exclamation"></i>
            <p>{children}</p>
        </div>
    )
}

export default Alert;