import { create } from "zustand";
import axios from "axios";

const useAppStore = create((set, get) => ({
  isLoading: false,
  errors: {
    categories: false,
  },
  homepageIsLoaded: false,
  projectsAreLoaded: false,
  categories: [],
  projects: [],
  personalInfo: {},

  setCategories: (categories) => {
    set(() => ({ categories: categories }));
  },

  setIsLoading: (value) => {
    set(() => ({ isLoading: value }));
  },

  setHomePageIsLoaded: () => {
    set(() => ({ homepageIsLoaded: true }));
  },

  fetchCategories: async () => {
    get().setIsLoading(true);
    const endpoint = process.env.REACT_APP_ENDPOINT;
    try {
      const { data: categories } = await axios.get(
        `${endpoint}/categories.php`
      );
      get().setCategories(categories);
    } catch (e) {
      const newErrors = { ...get().errors };
      newErrors.categories = true;
      set(() => ({ errors: newErrors }));
    }
  },

  fetchPersonalInfo: async () => {
    const endpoint = process.env.REACT_APP_ENDPOINT;
    try {
      const { data: info } = await axios.get(`${endpoint}/personal_info.php`);
      const newInfo = {};
      info.forEach((i) => {
        newInfo[i.info_name] = i.info_value;
      });
      set(() => ({ personalInfo: newInfo }));
    } catch (e) {
      console.log(e);
    }
  },

  fetchProjects: async (categoryId) => {
    set(() => ({ projectsAreLoaded: false }));
    const endpoint = process.env.REACT_APP_ENDPOINT;
    try {
      const { data: projects } = await axios.post(`${endpoint}/entries.php`, {
        id: categoryId,
      });
      set(() => ({ projects: projects }));
      set(() => ({ projectsAreLoaded: true }));
    } catch (e) {
      console.log(e);
    }
  },
}));

export default useAppStore;
